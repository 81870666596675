<script>
import axios from "axios";
export default {
  data() {
    axios.get(`https://dashboard.bigsisterprod.com/api/teams`).then((response) => (this.team = response.data));
    return {
      team: [],
    };
  },
  methods: {
    getTeam(id) {
      let data = this.team;
      return data.filter((item) => {
        return item.id == id;
      });
    },
  },
};
</script>

<template>
  <div>
    <section class="about team_member">
      <div
        class="container"
        v-for="member in getTeam($route.params.id)"
        :key="member.id"
      >
        <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
          {{ member.name }}
        </h1>
        <div class="topSection">
          <div class="content">
            <div v-html="member.about"></div>
            <!-- <p data-aos="fade-down" data-aos-duration="1000"> -->
            <!-- {{ member.about }} -->
            <!-- </p> -->
          </div>
          <figure data-aos="fade-left" data-aos-duration="1000">
            <img
              :src="'https://dashboard.bigsisterprod.com/' + member.image"
              alt=""
            />
          </figure>
        </div>
      </div>
    </section>
  </div>
</template>

<style></style>
